import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { dt } from "../../../../helpers/AppHelpers";
import {
  getCompanies,
  getCompanyServices,
  getPaymentTransactions,
} from "../../../../services/PsidService";
import {
  ICompany,
  ICompanyService,
  IPaymentTransactionResponse,
  IPsidFilter,
  IPsidStatusDetails,
} from "../../models/interfaces";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useAuth } from "../../../auth/core/Auth";
import CompanySearchDropDown from "../../reusableComponents/CompanySearchDropDown";
import ServicesSearchDropDown from "../../reusableComponents/ServicesSearchDropDown";
import { searchUtility } from "../../../../utils/searchUtility";
import {
  Modal,
  Container,
  Card,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { getPsidStatusDetail } from "../../../../services/PsidStatusDetail";
import { Header } from "react-bootstrap/lib/Modal";
import classes from "./style.module.css";

function PaymentTransaction() {
  const { auth } = useAuth();

  // const data = useMemo(() => MOCK_DATA, [])

  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [companyServices, setCompanyServices] = useState<ICompanyService[]>([]);
  const transactionType: any = useMemo(() => ["ALL", "1BILL", "CARD"], []);
  const [loader, setLoader] = useState(false);

  const initialFilter = {
    startDate: _newDate,
    endDate: _newDate,
    companyId: "0",
    serviceId: "0",
    pageIndex: currentPage - 1,
    pageSize: pageSize,
    search: "",
    transactionType: "ALL",
  };

  const [filter, setFilter] = useState<IPsidFilter>({
    startDate: _newDate,
    endDate: _newDate,
    companyId: "0",
    serviceId: "0",
    pageIndex: currentPage - 1,
    pageSize: pageSize,
    search: "",
    transactionType: "ALL",
  });
  const [paymentTransaction, setPaymentTransaction] =
    useState<IPaymentTransactionResponse>();

  let columns: any = useMemo(() => {
    let columns = [
      {
        Header: "S.No.",
        accessor: "srno",
      },
      {
        Header: "PSID",
        accessor: "consumerNumber",
      },
      {
        Header: "Consumer Name",
        accessor: "consumerName",
      },
      {
        Header: "Challan #",
        accessor: "challanNumber",
      },
      {
        Header: "Service",
        accessor: "serviceName",
      },
      {
        Header: "Bank",
        accessor: "bank",
      },
      {
        Header: "Transaction Amount",
        accessor: "transactionAmount",
      },
      {
        Header: "Transaction ID",
        accessor: "transactionAuthId",
      },
      {
        Header: "Transaction Date Time",
        accessor: "transactionDateTime",
      },
      {
        Header: "Challan Amount",
        accessor: "challanAmount",
      },
    ];

    if (
      ["ROLE_PITB_ADMIN", "ROLE_ADMIN", "PITB_FINANCE"].includes(
        auth?.role?.roleType
      )
    ) {
      columns.push({
        Header: "PITB charges",
        accessor: "pitbAmount",
      });
      if (filter.transactionType !== "CARD") {
        columns.push({
          Header: "1-link charges",
          accessor: "link1Amount",
        });
      }
    }

    return columns;
  }, [auth?.role?.roleType, loader]);
  const [consumerNumber, setConsumerNumber] = useState<string>();
  const [showModal, setShowModal] = useState(false);

  const [psidStatus, setPsidStatus] = useState<IPsidStatusDetails>();

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Payment Transactions" });
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  // useEffect(()=> {
  //     console.log(auth?.role?.roleType);
  // }, [paymentTransaction])

  const showCompanyDropdown = (): boolean => {
    return [
      "ROLE_PITB_ADMIN",
      "ROLE_ADMIN",
      "PITB_FINANCE",
      "ROLE_PITB_OPERATIONS",
    ].includes(auth?.role?.roleType);
  };

  const isDepartmentAdmin = (): boolean => {
    return auth?.role?.roleType == "ROLE_DEPARTMENT_ADMIN";
  };

  const getCompanyList = async () => {
    if (auth?.role.roleType == "ROLE_DEPARTMENT_ADMIN") {
      getCompanyServiceList(auth?.companyId);
    } else {
      const response = await getCompanies();
      setCompanies(response);
    }
  };

  const getCompanyServiceList = async (companyId: number) => {
    const response = await getCompanyServices(companyId);
    setCompanyServices(response);
  };

  const getReportData = async (init: boolean = false) => {
    setLoader(true);
    if (filter.transactionType !== "CARD") {
      columns.push({
        Header: "1-link charges",
        accessor: "link1Amount",
      });
    } else {
      const index = columns.findIndex(
        (column: any) => column.Header === "1-link charges"
      );
      if (index !== -1) {
        columns.splice(index, 1);
      }
    }
    const response = await getPaymentTransactions(
      init ? initialFilter : filter
    );
    if (response.status === 200) {
      const { content, totalAmount, totalRecord } = response.data;
      setTotalAmount(totalAmount);
      const paymentTransactionObj: IPaymentTransactionResponse = content[0];
      setPaymentTransaction(paymentTransactionObj);
      let rowData = paymentTransactionObj
        ? paymentTransactionObj?.paymentTransactionResponseList
        : [];
      rowData = rowData.map((rd: any, i) => {
        rd.srno = (currentPage - 1) * pageSize + i + 1;
        rd.transactionAmount = Number(rd.transactionAmount).toLocaleString();
        if (rd.rudaAmount) {
          rd.rudaAmount = Number(rd.rudaAmount).toLocaleString();
        }
        if (rd.pitbAmount) {
          rd.pitbAmount = Number(rd.pitbAmount).toLocaleString();
        }
        if (rd.link1Amount) {
          rd.link1Amount = rd.link1Amount;
        }
        return rd;
      });
      if (Number(totalRecord) <= filter.pageSize) {
        setCurrentPage(1);
      }
      setPageData((prevState: any) => ({
        ...prevState,
        isLoading: false,
        rowData: rowData,
        totalPages: 10,
        totalRows: Number(totalRecord),
      }));
    }
    setLoader(false);
  };

  // OnChange Handler

  const onChangeStartDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({
      ...filter,
      startDate: e.target.value,
      pageIndex: 0,
    }));
  };

  const onChangeEndDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({
      ...filter,
      endDate: e.target.value,
      pageIndex: 0,
    }));
  };

  const companyChangeHandler = (e: any) => {
    let companyId = "0";
    if (e.target.value) {
      // const company = companies.find(c => c.getId() === e.target.value)
      // setCompany(company);
      companyId = e.target.value;
      getCompanyServiceList(e.target.value);
    } else {
      setCompanyServices([]);
    }
    setCurrentPage(1);
    setFilter((filter: any) => ({
      ...filter,
      companyId,
      serviceId: "0",
      pageIndex: 0,
    }));
  };

  const onConsumerNumberClick = async (id: string) => {
    setLoader(true);
    setConsumerNumber(id);
    const psidDetails = await getPsidStatusDetail(id);
    setPsidStatus(psidDetails?.data?.content?.[0]);
    setLoader(false);
    setShowModal(true);
  };

  function onSearchChange(value: any) {
    searchUtility(value, setFilter, companies, getCompanyServiceList);
  }

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  const statusChangeHandler = (e: any) => {
    const transactionType = e.target.value;
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, transactionType }));
  };

  const serviceChangeHandler = (e: any) => {
    let serviceId = "0";
    if (e.target.value) {
      // const service = companyServices.find(s => s.getId() === e.target.value);
      serviceId = e.target.value;
      // setService(service)
    }
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, serviceId, pageIndex: 0 }));
  };

  const setPageIndex = (page: number) => {
    setCurrentPage(page);
    setFilter((filter: any) => ({ ...filter, pageIndex: page - 1 }));
  };

  const onLoadPageItems = (pageItem: number) => {
    setCurrentPage(1);
    setPageSize(pageItem);
    setFilter((filter: any) => ({
      ...filter,
      pageSize: pageItem,
      pageIndex: 0,
    }));
  };

  function exportExcel(): void {
    if (pageData.totalRows > 0) {
      const payload = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        companyId: filter.companyId,
        serviceId: filter.serviceId,
        search: -1,
        transactionType: filter.transactionType,
        totalItems: pageData.totalRows,
      };

      const userName: any = auth?.username;
      // console.log("Token", userName);
      window.location.assign(
        process.env.REACT_APP_API_URL +
          "/reporting/download/paymentTransactions/" +
          payload.startDate +
          "/" +
          payload.endDate +
          "/" +
          payload.serviceId +
          "/" +
          payload.companyId +
          "/" +
          payload.search +
          "/" +
          payload.transactionType +
          "/" +
          payload.totalItems +
          "/" +
          btoa(userName)
      );
    }
  }

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-md-3">
            <label className="filter-label">Search</label>
            <input
              className="form-control"
              type="text"
              value={filter?.search || ""}
              onChange={(e) => {
                const value = e.target.value.trim();
                if (
                  value === "" ||
                  (/^\d+$/.test(value) && value.length <= 14)
                ) {
                  onSearchChange(value);
                }
              }}
              placeholder="Consumer Number"
              maxLength={14}
            />
          </div>

          {showCompanyDropdown() && (
            <div className="col-md-3">
              <label className="filter-label">Company</label>
              {/* <select className="form-control" onChange={companyChangeHandler}>
                <option value="">All Companies</option>
                {companies.map((c: ICompany) => (
                  <option key={c.getId()} value={c.getId()}>
                    {c.getCompanyName()}
                  </option>
                ))}
              </select> */}
              <CompanySearchDropDown
                companies={companies}
                onChange={companyChangeHandler}
                value={filter.companyId}
              />
            </div>
          )}

          {!showCompanyDropdown() && <div className="col-md-3"></div>}

          <div className="col-md-3">
            <label className="filter-label">Services</label>
            {/* <select className="form-control" onChange={serviceChangeHandler}>
              <option value="">All Services</option>
              {companyServices.map((cs: ICompanyService) => (
                <option key={cs.getId()} value={cs.getId()}>
                  {cs.getServiceName()}
                </option>
              ))}
            </select> */}
            <ServicesSearchDropDown
              companyServices={companyServices}
              onChange={serviceChangeHandler}
              value={filter.serviceId}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">Transaction Type</label>
            <select
              className="form-control"
              onChange={statusChangeHandler}
              value={filter.transactionType}
              id="transactionType"
            >
              {transactionType.map((ps: string) => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label className="filter-label">Start date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.startDate || ""}
              onChange={onChangeStartDate}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">End date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.endDate || ""}
              onChange={onChangeEndDate}
            />
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={() => getReportData()}
              style={{ width: "100%" }}
            >
              Filter
            </button>
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttongrey"
              onClick={_initFilters}
              style={{ width: "100%" }}
            >
              Reset
            </button>
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttongrey"
              onClick={exportExcel}
              style={{ width: "100%" }}
            >
              Download Excel
            </button>
          </div>
        </div>
      </div>
      {pageData?.rowData && (
        <div className="card p-3">
          <AppTable
            columns={columns}
            data={pageData.rowData.map((row: any) => ({
              ...row,
              consumerNumber: (
                <div
                  onClick={() => onConsumerNumberClick(row?.consumerNumber)}
                  style={{
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.textDecoration = "underline")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.textDecoration = "none")
                  }
                >
                  {row.consumerNumber}
                </div>
              ),
            }))}
            isLoading={pageData.isLoading || loader}
          />
          <Pagination
            totalRows={pageData.totalRows}
            pageChangeHandler={setPageIndex}
            rowsPerPage={filter.pageSize}
            currentPage={currentPage}
            pageItemChangeHandler={onLoadPageItems}
          />
          <div className="row no___margin">
            <div className="d-flex w-100 justify-content-between">
              {showCompanyDropdown() && (
                <div
                  style={{
                    backgroundColor: "rgba(233, 248, 242, 1)",
                  }}
                  className="p-2 text-center rounded col-md-3"
                >
                  <span className={classes.textStyle}>
                    <b className={classes.textStyleBold}>Transaction Amount </b>
                    {Number(totalAmount).toLocaleString()} PKR
                  </span>
                </div>
              )}
              <div
                style={{
                  backgroundColor: "rgba(236, 241, 255, 1)",
                }}
                className="p-2 text-center rounded col-md-3"
              >
                <span className={classes.textStyle}>
                  <b className={classes.textStyleBold}>Company Collection </b>
                  {Number(
                    paymentTransaction?.companyTotalAmount
                  ).toLocaleString()}
                  PKR
                </span>
              </div>
              {!isDepartmentAdmin() && (
                <div
                  style={{
                    backgroundColor: "rgba(208, 149, 255, 0.12)",
                  }}
                  className="p-2 text-center rounded col-md-3"
                >
                  <span className={classes.textStyle}>
                    <b className={classes.textStyleBold}>PITB Total Charges</b>{" "}
                    {Number(
                      paymentTransaction?.pitbTotalAmount
                    ).toLocaleString()}{" "}
                    PKR
                  </span>
                </div>
              )}
              {filter.transactionType !== "CARD" && (
                <div
                  style={{
                    backgroundColor: "rgba(82, 245, 255, 0.12)",
                  }}
                  className="p-2 text-center rounded"
                >
                  <span className={classes.textStyle}>
                    <b className={classes.textStyleBold}>
                      1-Link Total Charges
                    </b>{" "}
                    {Number(
                      paymentTransaction?.link1TotalAmount
                    ).toLocaleString()}{" "}
                    PKR{" "}
                  </span>
                </div>
              )}
            </div>
            {paymentTransaction?.shouldShowBankInfo && (
              <div className="col-md-6">
                <div>
                  <span>
                    <b>Account Title : </b>
                    {paymentTransaction?.accountTitle}
                  </span>
                </div>
                <div>
                  <span>
                    <b>Account Number :</b> {paymentTransaction?.accountNumber}
                  </span>
                </div>
                <div>
                  <span>
                    <b>IBAN :</b> {paymentTransaction?.iban}
                  </span>
                </div>
                <div>
                  <span>
                    <b>Bank Name :</b> {paymentTransaction?.bank}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-lg"
      >
        <Modal.Header>
          <Modal.Title>PSID status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="d-flex justify-content-between">
            <div>
              <div>
                <strong className="w-25 text-center">Consumer Number</strong>
              </div>
              <div className="text-center mt-2">{consumerNumber}</div>
            </div>
            <div>
              <div>
                <strong className="w-25 text-center">Confirmation</strong>
              </div>
              <div className="text-center mt-2">
                {psidStatus?.isConfirmed ? (
                  <i
                    className="bi bi-check-circle"
                    style={{ color: "green" }}
                  ></i>
                ) : (
                  <i className="bi bi-x-circle" style={{ color: "red" }}></i>
                )}
              </div>
            </div>
            <div className="w-25 text-center">
              <div>
                <strong>Intimation</strong>
              </div>
              <div className="text-center mt-2">
                {psidStatus?.isIntimated ? (
                  <i
                    className="bi bi-check-circle"
                    style={{ color: "green" }}
                  ></i>
                ) : (
                  <i className="bi bi-x-circle" style={{ color: "red" }}></i>
                )}
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaymentTransaction;
