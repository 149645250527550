import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import { AuthRoutes } from "../modules/auth/AuthRoutes";
import { useAuth } from "../modules/auth/core/Auth";
import Landing from "../pages/Landing";
import { PrivateRoutes } from "./PrivateRoutes";

const AppRoutes: FC = () => {
    const {auth} = useAuth()
    return (
        <BrowserRouter basename='/'>
            <Routes>
                <Route element={<App />}>
                    {/* <Route path='error/*' element={<ErrorsPage />} />
    <Route path='logout' element={<Logout />} /> */}
                    <Route path="/" element={<Landing />} />
                    {auth ? (
                        <>
                        <Route path='app/*' element={<PrivateRoutes />} />
                        <Route index element={<Navigate to='app/dashboard' />} />
                        </>
                    ) : (
                        <>
                        <Route path='auth/*' element={<AuthRoutes />} />
                        <Route path='*' element={<Navigate to='/auth/login' />} />
                        </>
                    )}
                    
                    <Route path='*' element={<Navigate to='/' />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export {AppRoutes}