import moment from "moment";
import React, { useMemo, useState, useEffect } from "react";
import {
  ICompany,
  ICompanyService,
  IPsidFilter,
} from "../../models/interfaces";
import { dt } from "../../../../helpers/AppHelpers";
import { IPsidResponse } from "../../models/interfaces/IPsidResponse";
import {
  getCompanies,
  getCompanyServices,
  getPsidList,
} from "../../../../services/PsidService";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { useAuth } from "../../../auth/core/Auth";
import CompanySearchDropDown from "../../reusableComponents/CompanySearchDropDown";
import ServicesSearchDropDown from "../../reusableComponents/ServicesSearchDropDown";
import { searchUtility } from "../../../../utils/searchUtility";

function PsidList() {
  const { auth } = useAuth();
  const [loader, setLoader] = useState(false);

  const COLUMNS = [
    {
      Header: "S.No.",
      accessor: "srno",
    },
    {
      Header: "Challan #",
      accessor: "challanNumber",
    },
    {
      Header: "Consumer Name",
      accessor: "consumerName",
    },
    {
      Header: "PSID",
      accessor: "consumerNumber",
    },
    {
      Header: "Service",
      accessor: "serviceName",
    },
    {
      Header: "Amount",
      accessor: "amountWithinDueDate",
    },
    {
      Header: "Payment Status",
      accessor: "billStatus",
    },
    {
      Header: "Expired",
      accessor: "isExpired",
    },
    {
      Header: "Due Date",
      accessor: "dueDate",
    },
  ];

  const updateHeaderContext = useUpdateHeaderContext();
  const columns: any = useMemo(() => COLUMNS, []);
  const transactionType: any = useMemo(() => ["ALL", "1BILL", "CARD"], []);
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [companyServices, setCompanyServices] = useState<ICompanyService[]>([]);
  const initialFilter = {
    startDate: _newDate,
    endDate: _newDate,
    companyId: "0",
    serviceId: "0",
    pageIndex: currentPage - 1,
    pageSize: 10,
    search: "",
    transactionType: transactionType[0],
  };
  const [filter, setFilter] = useState<IPsidFilter>({
    startDate: _newDate,
    endDate: _newDate,
    companyId: "0",
    serviceId: "0",
    pageIndex: currentPage - 1,
    pageSize: 10,
    search: "",
    transactionType: transactionType[0],
  });

  const [psid, setPsid] = useState<IPsidResponse[]>();

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Psid List" });
    getCompanyList();
  }, []);

  //keep an eye on table pagination. If Page size changes. function will be called
  useEffect(() => {
    // console.log('useEffect :', filter)
    getReportData();
  }, [currentPage, pageSize]);

  const getCompanyList = async () => {
    const response = await getCompanies();
    setCompanies(response);
  };

  const getCompanyServiceList = async (companyId: number) => {
    const response = await getCompanyServices(companyId);
    setCompanyServices(response);
  };

  const getReportData = async (init: boolean = false) => {
    setLoader(true);
    const response = await getPsidList(init ? initialFilter : filter);
    if (response.status === 200) {
      const { content, totalAmount, totalRecord } = response.data;
      const psidObj: IPsidResponse[] = content;
      setPsid(psidObj);
      // console.log(psidObj);
      setPageData((prevState: any) => ({
        ...prevState,
        isLoading: false,
        rowData: psidObj
          ? psidObj.map((p: any, i) => {
              p.srno = (currentPage - 1) * pageSize + i + 1;
              p.isExpired = p.isExpired ? "Yes" : "No";
              return p;
            })
          : [],
        totalPages: 10,
        totalRows: Number(totalRecord),
      }));
    }
    setLoader(false);
  };

  // OnChange Handler

  const onChangeStartDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  const companyChangeHandler = (e: any) => {
    let companyId = "0";
    if (e.target.value) {
      companyId = e.target.value;
      getCompanyServiceList(e.target.value);
    } else {
      setCompanyServices([]);
    }
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, companyId, serviceId: "0" }));
  };

  const serviceChangeHandler = (e: any) => {
    let serviceId = "0";
    if (e.target.value) {
      serviceId = e.target.value;
    } else {
      setCompanyServices([]);
    }
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, serviceId }));
  };

  const statusChangeHandler = (e: any) => {
    const transactionType = e.target.value;
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, transactionType }));
  };

  function onSearchChange(value: any) {
    searchUtility(value, setFilter, companies, getCompanyServiceList);
  }

  const setPageIndex = (page: number) => {
    setCurrentPage(page);
    setFilter((filter: any) => ({ ...filter, pageIndex: page - 1 }));
  };

  const onLoadPageItems = (pageItem: number) => {
    setCurrentPage(1);
    setPageSize(pageItem);
    setFilter((filter: any) => ({
      ...filter,
      pageSize: pageItem,
      pageIndex: 0,
    }));
  };

  function exportExcel(): void {
    if (pageData.totalRows > 0) {
      const payload = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        companyId: filter.companyId,
        serviceId: filter.serviceId,
        search: -1,
        totalItems: pageData.totalRows,
      };

      const userName: any = auth?.username;
      // console.log("Token", userName);
      window.location.assign(
        process.env.REACT_APP_API_URL +
          "/reporting/download/psids/" +
          payload.startDate +
          "/" +
          payload.endDate +
          "/" +
          payload.serviceId +
          "/" +
          payload.companyId +
          "/" +
          payload.search +
          "/" +
          payload.totalItems +
          "/" +
          btoa(userName)
      );
    }
  }

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-md-3">
            <label className="filter-label">Search</label>
            <input
              className="form-control"
              type="text"
              value={filter?.search || ""}
              onChange={(e) => {
                const value = e.target.value.trim();
                if (
                  value === "" ||
                  (/^\d+$/.test(value) && value.length <= 14)
                ) {
                  onSearchChange(value);
                }
              }}
              placeholder="Consumer Number"
              maxLength={14}
            />
          </div>

          <div
            className="col-md-3"
            v-if="getUserRole == 'ROLE_ADMIN' || getUserRole == 'PITB_FINANCE'"
          >
            <label className="filter-label">Company</label>
            {/* <select className="form-control" onChange={companyChangeHandler}>
              <option value="">All Companies</option>
              {companies.map((c: ICompany) => (
                <option key={c.getId()} value={c.getId()}>
                  {c.getCompanyName()}
                </option>
              ))}
            </select> */}
            <CompanySearchDropDown
              companies={companies}
              onChange={companyChangeHandler}
              value={filter.companyId}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">Services</label>
            {/* <select className="form-control" onChange={serviceChangeHandler}>
              <option value="">All Services</option>
              {companyServices.map((cs: ICompanyService) => (
                <option key={cs.getId()} value={cs.getId()}>
                  {cs.getServiceName()}
                </option>
              ))}
            </select> */}
            <ServicesSearchDropDown
              companyServices={companyServices}
              onChange={serviceChangeHandler}
              value={filter.serviceId}
            />
          </div>

          <div className="col-md-3">
            <label className="filter-label">Transaction Type</label>
            <select
              className="form-control"
              onChange={statusChangeHandler}
              value={filter?.transactionType}
            >
              {transactionType.map((ps: string) => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label className="filter-label">Start date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.startDate || ""}
              onChange={onChangeStartDate}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">End date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.endDate || ""}
              onChange={onChangeEndDate}
            />
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={() => getReportData()}
              style={{ width: "100%" }}
            >
              Filter
            </button>
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttongrey"
              onClick={_initFilters}
              style={{ width: "100%" }}
            >
              Reset
            </button>
          </div>
          {/* Download button */}
          {/* <div className="col-md-3">
                        <button className="mt-8 dashboard-filter-buttongrey" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" onClick={exportExcel}>Download Excel</button>
                    </div> */}
        </div>
      </div>
      {pageData?.rowData && (
        <div className="card p-3">
          <AppTable
            columns={columns}
            data={pageData.rowData}
            isLoading={pageData.isLoading || loader}
          />
          <Pagination
            totalRows={pageData.totalRows}
            pageChangeHandler={setPageIndex}
            rowsPerPage={filter.pageSize}
            currentPage={currentPage}
            pageItemChangeHandler={onLoadPageItems}
          />
        </div>
      )}
    </div>
  );
}

export default PsidList;
