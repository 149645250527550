import { ICompanyService } from "../modules/app/models/interfaces";

export const searchUtility = (value: string, setFilter: any, companies: any, getCompanyServiceList: any) => {
    if (value.length < 2) {
        setFilter((filter: any) => ({
          ...filter,
          search: value,
          pageIndex: 0,
          companyId: "0",
          serviceId: "0",
        }));
      } else if (value.length > 2 && value.length < 15) {
        const serviceCode =
          value.length < 14
            ? value[1] + value[2]
            : value[1] + value[2] + value[3];
        let companyId_ = "0";
        let serviceId_ = "0";
        let count = 0;
        companies?.forEach((company: any) => {
          company?.getServices().forEach((service: ICompanyService) => {
            if (service?.getServiceCode() === serviceCode) {
              serviceId_ = String(service.getId());
              companyId_ = String(company.getId());
              count += 1;
              return;
            }
          });
        });
        if (
          companies?.some((company: any) => company.getId() === Number(companyId_))
        ) {
          getCompanyServiceList(Number(companyId_));
          if (companyId_ !== "0" && serviceId_ !== "0") {
            setFilter((filter: any) => ({
              ...filter,
              search: value,
              pageIndex: 0,
              companyId: companyId_,
            }));
            if (count === 1) {
              setFilter((filter: any) => ({
                ...filter,
                search: value,
                pageIndex: 0,
                serviceId: serviceId_,
              }));
            }
          }
        } else {
          setFilter((filter: any) => ({
            ...filter,
            search: value,
            pageIndex: 0,
          }));
        }
      } else {
        setFilter((filter: any) => ({
          ...filter,
          search: value,
          pageIndex: 0,
        }));
      }
}